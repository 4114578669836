import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import ActionIcon from 'src/components/general/ActionIcon';
import { Form, Row, Col, Container, Table, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from 'src/components/general/FormFieldError.js';
import { getFundRequestManualItems, getFundRequestsToEdit } from 'src/redux/fundRequests/fundRequestsReducer.js';
import { deletedFundRequestManualItems, setFundRequestManualItems, clearFundRequestManualItems } from 'src/redux/fundRequests/fundRequestsActions.js';
import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer.js';
import { dataPeriodsContructor, formatterPeso, getYearsOptionsByParam, parseIntOrUndefined, onKeyDownEnter } from 'src/utils/utils.js';
import { tryGetAdministrativeDocumentByPeriodId } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { getAdministrativeDocumentIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { isNumeric } from 'src/services/validationService';

function FundRequestManualControl() {
    const dispatch = useDispatch();
    const { errors } = useFormContext();
    const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const isFetchingAdministrativeDocument = useSelector(state => getAdministrativeDocumentIsFetching(state));
    const fundRequestEditSelected = useSelector(state => getFundRequestsToEdit(state));
    const fundRequestManualItems = useSelector(state => getFundRequestManualItems(state));
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [affectationYear, setAffectationYear] = useState('');
    const [idItem, setIdItem] = useState(1);
    const [organismCode, setOrganismCode] = useState('');
    const [organismNumber, setOrganismNumber] = useState('');
    const [organismYear, setOrganismYear] = useState('Año');
    const [organismCodeData, setOrganismCodeData] = useState('');
    const paramsToSend = {
        idItem: idItem,
        description,
        amount,
        affectationYear,
        administrativeDocument: {
            year: organismYear,
            codeOrganism: organismCode,
            number: organismNumber
        }
    };

    // select de tipo de afectacion 
    const affectationYearList = [
        { id: 1, value: `Cuenta Presupuesto Ejercicio ${globalSelectedPeriod?.year}` },
        { id: 2, value: `Cuenta Presupuesto Residuos Pasivos ${globalSelectedPeriod?.year - 1}` },
        { id: 3, value: `Cuenta Presupuesto Residuos Pasivos ${globalSelectedPeriod?.year - 2}` },
        { id: 4, value: `Partidas del Ejercicio – Erog. Figurativa p/ financiar Erog. Ctes` },
        { id: 5, value: `Partidas del Ejercicio – Erog. Figurativa p/ financiar Erog. de Capital` },
        { id: 6, value: `RP ${globalSelectedPeriod?.year - 1} – Erog. Figurativa p/ financiar Erog. Ctes` },
        { id: 7, value: `RP ${globalSelectedPeriod?.year - 1} – Erog. Figurativa p/ financiar Erog. de Capital` },
        { id: 8, value: `RP ${globalSelectedPeriod?.year - 2} – Erog. Figurativa p/ financiar Erog. Ctes` },
        { id: 9, value: `RP ${globalSelectedPeriod?.year - 2} – Erog. Figurativa p/ financiar Erog. de Capital` },
    ];

    // Year field
    const minimumYear = 2018;
    const yearsOptions = getYearsOptionsByParam(minimumYear, globalSelectedPeriod?.year);

    // Number field
    const maxNumber = 2147483647;

    const handleManualInputs = () => {
        let newIdItem = idItem + 1;
        if (description !== '' && amount !== '' && affectationYear !== '') {
            dispatch(setFundRequestManualItems(paramsToSend));
            setIdItem(newIdItem);
            setDescription('');
            setAmount('');
            setAffectationYear('');
            setOrganismCode('');
            setOrganismNumber('');
            setOrganismYear('Año');
            setOrganismCodeData('');
        }
    };
    const handleClearManualItems = (idItem) => {
        dispatch(deletedFundRequestManualItems(idItem));
    };

    const handleChangeOrganismCode = value => {
        const intValue = parseIntOrUndefined(value || 0);
        const valueLimit = 9999;
        if (intValue <= valueLimit && intValue > 0) {
            setOrganismCode(parseIntOrUndefined(value));
        } else if (intValue == 0) {
            setOrganismCode('');
        }
    };

    const handleChangeOrganismNumber = value => {
        const intValue = parseIntOrUndefined(value || 0);
        const valueLimit = 2147483647;
        if (intValue <= valueLimit && intValue > 0) {
            setOrganismNumber(parseIntOrUndefined(value));
        } else if (intValue == 0) {
            setOrganismNumber('');
        }
    }

    const handleChangeOrganismYear = value => {
        setOrganismYear(value);
    };

    const totalAmount = () => {
        let sumAmount = 0;
        fundRequestManualItems?.map(item => sumAmount = new Number(sumAmount) + new Number(item?.amount))
        return sumAmount;
    };

    let organismCodeValidate = (value) => {
        if (value > 999 && value < 10000) return true
        return false;
    };

    // onBlurInputs
    const onBlurOrganismDataInputs = () => {

        setOrganismCodeData({});

        const yearSelected = organismYear;
        const numberSelected = organismNumber;
        const organismCodeSelected = organismCode;

        const validOrganismCode = organismCodeValidate(organismCodeSelected);

        if (validOrganismCode && numberSelected && isNumeric(yearSelected)) {
            const params = {
                year: parseIntOrUndefined(yearSelected),
                number: parseIntOrUndefined(numberSelected),
                organismCode: organismCodeSelected,
                periodId: globalSelectedPeriod?.id
            };
            dispatch(tryGetAdministrativeDocumentByPeriodId(params))
                .then(response => setOrganismCodeData(response?.id ? response : {}));
        }
    };

    const allowAddConcept = (description != '' && amount != '' && affectationYear != '' && organismCodeValidate(organismCode) && organismNumber != '' && isNumeric(organismYear));

    useEffect(() => {
        dispatch(clearFundRequestManualItems());
        for (let i = 0; i < fundRequestEditSelected?.manualInputs?.length; i++) {
            const manualInputs = {
                idItem: fundRequestEditSelected?.manualInputs[i]?.id,
                description: fundRequestEditSelected?.manualInputs[i]?.description,
                amount: fundRequestEditSelected?.manualInputs[i]?.amount,
                affectationYear: fundRequestEditSelected?.manualInputs[i]?.affectationYear,
                administrativeDocument: fundRequestEditSelected?.manualInputs[i]?.administrativeDocument
            };
            dispatch(setFundRequestManualItems(manualInputs));
        }
    }, [])

    return (
        <>
            <Container className="px-5">
                <p className="pt-4">Agregar Conceptos de Pago</p>
                <Table size="sm">
                    <thead>
                        <tr className='text-white-color'>
                            <th className='text-center' width="30%">Expediente</th>
                            <th className='text-center' width="25">Concepto</th>
                            <th className='text-center' width="20%">Tipo de Afectación</th>
                            <th className='text-center' width="15%">Importe</th>
                            <th className='text-center' width="10%">Acción</th>
                        </tr>
                    </thead>
                    <tbody className='text-black-color'>
                        <tr >
                            <td className='text-center align-middle input-group'>
                                <Form.Control
                                    type='number'
                                    id='expediente-data'
                                    className='text-center'
                                    placeholder='Org.'
                                    value={organismCode}
                                    disabled={isFetchingAdministrativeDocument}
                                    onBlur={onBlurOrganismDataInputs}
                                    tabIndex='1'
                                    onChange={e => handleChangeOrganismCode(e.target.value)}
                                    onKeyDown={event => onKeyDownEnter(event)}
                                />
                                <Form.Control
                                    type='number'
                                    placeholder='Nro.'
                                    className='text-center'
                                    value={organismNumber}
                                    onChange={e => handleChangeOrganismNumber(e.target.value)}
                                    disabled={isFetchingAdministrativeDocument}
                                    onBlur={onBlurOrganismDataInputs}
                                    tabIndex='2'
                                    min='1'
                                    max={maxNumber}
                                    onKeyDown={event => onKeyDownEnter(event)}
                                />
                                <Form.Control
                                    as='select'
                                    disabled={isFetchingAdministrativeDocument}
                                    onBlur={onBlurOrganismDataInputs}
                                    tabIndex='3'
                                    className='text-center'
                                    value={organismYear}
                                    onChange={e => handleChangeOrganismYear(e.target.value)}
                                >
                                    <option value={undefined}>Año</option>
                                    {
                                        yearsOptions?.map((item, idx) => (<option value={item} key={idx}>
                                            {item}
                                        </option>))
                                    }
                                </Form.Control>
                                <div className='text-center align-middle ml-1 mt-2'>
                                    {
                                        isFetchingAdministrativeDocument ?
                                            <Spinner animation='border' size={"sm"} />
                                            :
                                            <FontAwesomeIcon size='lg' className='text-center' style={{ "color": "#085B84" }} title={organismCodeData?.title ? "Asunto: " + organismCodeData?.title : 'Asunto no disponible.'} icon={faInfoCircle} />
                                    }
                                </div>
                            </td>
                            <td className='text-center'>
                                <Form.Group as={Row}>
                                    <Col className='px-0  ml-3 mr-3'>
                                        <Form.Control
                                            placeholder='Ingrese aquí el concepto'
                                            name='concepto'
                                            maxLength='255'
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            onKeyDown={event => onKeyDownEnter(event)}
                                        />
                                    </Col>
                                </Form.Group>
                            </td>
                            <td className='text-center'>
                                <Form.Group as={Row}>
                                    <Col className='px-0 ml-3 mr-3'>
                                        <Form.Control
                                            as="select"
                                            name='ejercicio'
                                            value={affectationYear}
                                            onChange={e => setAffectationYear(e.target.value)}
                                        >
                                            <option>Seleccionar...</option>
                                            {affectationYearList.map(item =>
                                                <option key={item.id} value={item?.value}>
                                                    {item?.value}
                                                </option>
                                            )}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </td>
                            <td className='text-center'>
                                <Form.Group as={Row}>
                                    <Col className='px-0 ml-3 mr-3'>
                                        <Form.Control
                                            type='number'
                                            placeholder='ej: $1.234,56'
                                            name='importe'
                                            maxLength='255'
                                            //thousandSeparator='.' decimalSeparator=','
                                            //decimalScale='2'
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                            onKeyDown={event => onKeyDownEnter(event)}
                                        />
                                        <FormFieldError errors={errors?.importe} />
                                    </Col>
                                </Form.Group>
                            </td>
                            <td className='text-center'>
                                <ActionIcon
                                    size='lg'
                                    toolTipText='Agregar Orden de Pago'
                                    icon={faPlus}
                                    className='text-primary'
                                    disabled={!allowAddConcept || isFetchingAdministrativeDocument}
                                    onClick={() => handleManualInputs()}
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
            <br></br>
            {
                (fundRequestManualItems?.length > 0) ?
                    <Container className="px-5">
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr className='text-white-color'>
                                    <th className='text-center' width="30%">Expediente</th>
                                    <th className='text-center' width="25%">Concepto</th>
                                    <th className='text-center' width="20%">Tipo de Afectación</th>
                                    <th className='text-center' width="15%">Importe</th>
                                    <th className='text-center' width="10%">Acción</th>
                                </tr>
                            </thead>
                            {fundRequestManualItems?.map((item, idx) => {
                                return (
                                    <tbody className='text-black-color' key={idx}>
                                        <tr>
                                            <td className='text-center'>
                                                {item?.administrativeDocument?.codeOrganism + '-' + item?.administrativeDocument?.number + '/' + item?.administrativeDocument?.year}
                                            </td>
                                            <td className='text-center'>
                                                {item?.description}
                                            </td>
                                            <td className='text-center'>
                                                {item?.affectationYear}
                                            </td>
                                            <td className='text-right'>
                                                {formatterPeso.format(item?.amount)}
                                            </td>
                                            <td className='text-center'>
                                                <ActionIcon
                                                    size='lg'
                                                    toolTipText='Remover'
                                                    icon={faTimes}
                                                    className='text-danger'
                                                    onClick={() => handleClearManualItems(item?.idItem)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                )
                            })}
                        </Table>
                        <p className="text-right">
                            <strong>Importe total: {formatterPeso.format(totalAmount())}</strong>
                        </p>
                    </Container>
                    : <Container className="px-5 text-center">
                        <span>
                            <FontAwesomeIcon icon={faExclamationTriangle} /> No hay Conceptos asociados
                        </span>
                    </Container>
            }
        </>
    )
}

export default FundRequestManualControl;