import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { Container, Card, Form, Col, Table, Tabs, Tab, Alert } from 'react-bootstrap';
import { faFilePdf, faSearch, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PageTitle from 'src/components/general/PageTitle';
import { getAccessToken } from 'src/redux/login/loginReducer';
import { showWarning } from 'src/redux/globalData/globalDataActionCreator';
import { getReportByURL } from 'src/services/reportsServices';
import { isNotEmptyArray } from 'src/services/validationService';
import { parseIntOrUndefined, formatterPeso, downloadStreamFile, numberNegativeRed, getOrderPayTypeValueToShow } from 'src/utils/utils';
import ActionIcon from 'src/components/general/ActionIcon';
import { associatedVouchers, emptyMovementExp, reportExped, dateImpact, nroCredit, attachedFiles, amountNumber, seatNumber, dateOfOperation, typesOfAffectation, subpartialDeparture } from 'src/utils/label';
import moment from 'moment';

import { clearListAdministrativeDocumentHistoryData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import { tryGetAdministrativeDocumentHistory } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { getAdministrativeDocumentHistoryData, getAdministrativeDocumentHistoryIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';

import AdministrativeDocumentForm from 'src/components/forms/administrativeDocument/administrativeDocumentForm';
import ReportExpedientTable from 'src/components/pages/Reports/ReportExpedientTable'

import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

import { tryListOrderPayByAdministrativeDocument, tryOrderPayById } from 'src/redux/orderPay/orderPayActionCreator';
import { getOrderPayListByAdministrativeDocumentListData } from 'src/redux/orderPay/orderPayReducer';
import DetailModal from '../FundRequests/modal/DetailModal';
import FundRequestDetailModal from '../Reports/FundRequestDetailModal';
import DeliveryOrderDetailModal from '../Reports/DeliveryOrderDetailModal';
import { tryGetFundRequestsById } from '../../../redux/fundRequests/fundRequestsActionCreator';
import { tryDeliveryOrderById } from '../../../redux/deliveryOrder/deliveryOrderActionCreator';

const ReportAffectationsByAdministrativeDocumentPage = () => {

	const dispatch = useDispatch();
	const accessToken = useSelector(state => getAccessToken(state));
	const hookFormMethods = useForm();
	const { handleSubmit, getValues } = hookFormMethods;

	// administrative document history
	const administrativeDocumentHistory = useSelector(state => getAdministrativeDocumentHistoryData(state));
	const administrativeDocumentHistoryIsFetching = useSelector(state => getAdministrativeDocumentHistoryIsFetching(state));
	const hasAdministrativeDocument = isNotEmptyArray(administrativeDocumentHistory);

	//Period
	const idSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state)?.id);

	const orderPayList = useSelector(state => getOrderPayListByAdministrativeDocumentListData(state));

	// Search administrative document
	const onClickSearchAdministrativeDocumentHistory = event => {
		const paramsToSendHistory = {
			organismCode: event?.organismCode,
			number: parseIntOrUndefined(event?.number),
			year: parseIntOrUndefined(event?.year),
			period_id: idSelectedPeriod
		};
		dispatch(tryGetAdministrativeDocumentHistory(paramsToSendHistory))
			.then(responseData => {
				if (responseData?.status == 200) {
					if (responseData?.data?.data?.length == 0) {
						showWarning(emptyMovementExp);
					}
				}
			});
		dispatch(tryListOrderPayByAdministrativeDocument(paramsToSendHistory));
	};

	const [showPayOrderModal, setShowPayOrderModal] = useState(false);
	const [activeOP, setActiveOP] = useState(false);
	const onClickPayOrderDetail = item => {
		setActiveOP(item);
		setShowPayOrderModal(true);
	};

	const [showFundRequestDetailModal, setShowFundRequestDetailModal] = useState(false);
	const [activeFR, setActiveFR] = useState(false);
	const onClickFundRequestDetail = item => {
		setActiveFR(item);
		setShowFundRequestDetailModal(true);
	};

	const [showDeliveryOrderDetailModal, setShowDeliveryOrderDetailModal] = useState(false);
	const [activeDO, setActiveDO] = useState(false);
	const onClickDeliveryOrderDetail = item => {
		setActiveDO(item);
		setShowDeliveryOrderDetailModal(true);
	};

	// Get transaction report
	const clickDownloadTransactionReportPDF = reportUrl => {
		getReportByURL(accessToken, reportUrl)
			.then(response => {
				if (response?.status == 200) {
					downloadStreamFile(response, 'reporte_transaccion');
				}
			});
	};

	// Clear old data
	useEffect(() => {
		dispatch(clearListAdministrativeDocumentHistoryData());
	}, []);

	useEffect(() => {
		orderPayList && (async () => {
			const fetchDetails = async (orderPay, index) => {
				const orderPayDetail = await dispatch(tryOrderPayById(orderPay.id));
				const fundRequest = orderPayDetail?.fundRequest;
				if (fundRequest) {
					const fundRequestDetail = await dispatch(tryGetFundRequestsById(fundRequest.id));
					const deliveryOrderId = fundRequestDetail?.deliveryOrderId;
					if (deliveryOrderId)
						orderPayDetail.fundRequest.deliveryOrder = (await dispatch(tryDeliveryOrderById(deliveryOrderId)))?.data;
				}
				orderPayList[index] = orderPayDetail;
			};
			await Promise.all(orderPayList.map(fetchDetails));
		})();
	}, [orderPayList]);

	// Transaction row
	const transactionRow = transactionData => <tr>
		<td colSpan='6' className='text-left align-middle'>
			<span className='font-weight-bold'>Transacción {moment(transactionData?.transaction?.created_at).format('DD/MM/YYYY HH:mm')}</span> - Extracto: {transactionData?.transaction?.detail || "-"}
		</td>
		<td></td>
		<td>
			<div className='d-flex justify-content-around'>
				<ActionIcon size='lg' id={`descarga-${18}`} toolTipText={'Descargar'} icon={faFilePdf} onClick={() => clickDownloadTransactionReportPDF(transactionData?.transaction?.reportUrl)} />
			</div>
		</td>
	</tr>;

	// Affectations row
	const affectationRow = affectationData => <tr>
		<td className='text-center align-middle'>
			{affectationData?.accountingNumber}
		</td>
		<td className='text-center align-middle'>
			{moment(affectationData?.createdAt).format('DD/MM/YYYY')}
		</td>
		<td className='text-left align-middle'>
			{affectationData?.affectationStatusLabel}
		</td>
		<td className='text-center align-middle'>
			{moment(affectationData?.affectationDate).format('DD/MM/YYYY')}
		</td>
		<td className='text-center align-middle'>
			{affectationData?.subPartialBudget?.number}
		</td>
		<td className='text-left align-middle'>
			{affectationData?.budgetType + ': ' + affectationData?.budgetLabel}
		</td>
		<td className={`${numberNegativeRed(affectationData?.amount)} text-right align-middle`}>
			{formatterPeso.format(affectationData?.amount)}
		</td>
		<td></td>
	</tr>;

	return <>
		<Container fluid>
			<Card className='mb-3'>
				<Card.Header className='d-flex justify-content-between'>
					<h1 className="h6 mt-1 mb-0">Reporte comprobantes por expediente</h1>
					<a className='text-white'
						target="_blank"
						href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/operador-servicios/#comprobante-por-expediente'
					>
						<FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
						<small>Ver manual de uso</small>
					</a>
				</Card.Header>
				<Card.Body>
					<FormProvider {...hookFormMethods}>
						<Form onSubmit={handleSubmit(onClickSearchAdministrativeDocumentHistory)}>
							<AdministrativeDocumentForm
								type='reportAdministrativeDocument'
								administrativeDocumentHistoryIsFetching={administrativeDocumentHistoryIsFetching}
							/>
							{
								hasAdministrativeDocument
									?
									<>
										<ReportExpedientTable {...getValues()} period_id={idSelectedPeriod} />
										<Tabs defaultActiveKey="budget" className="mb-3">
											<Tab eventKey="budget" title="Presupuestarios">
												<Col>
													<div className='d-flex justify-content-between align-items-center mb-3'>
														<span className='text-black-color h5 m-0'>
															{associatedVouchers}:
														</span>
													</div>
													<Table striped bordered hover size='sm'>
														<thead>
															<tr>
																<th className='text-center align-middle'>{seatNumber}</th>
																<th className='text-center align-middle'>{dateOfOperation}</th>
																<th className='text-center align-middle'>{typesOfAffectation}</th>
																<th className='text-center align-middle'>{dateImpact}</th>
																<th className='text-center align-middle'>{subpartialDeparture}</th>
																<th className='text-center align-middle'>{nroCredit}</th>
																<th className='text-center align-middle'>{amountNumber}</th>
																<th className='text-center align-middle'>{attachedFiles}</th>
															</tr>
														</thead>
														<tbody className='text-black-color'>
															{administrativeDocumentHistory?.map(transactionData => <>
																{(transactionData?.transaction) ?
																	transactionRow(transactionData)
																	: undefined
																}
																{transactionData?.affectations?.map(affectationData => <>
																	{affectationRow(affectationData)}
																</>)
																}
															</>)
															}
														</tbody>
													</Table>
												</Col>
											</Tab>
											<Tab eventKey="finance" title="Financieros">
												<Table bordered hover size='sm' className='mb-5'>
													<thead>
														<tr>
															<th className='text-center align-middle'>Numero año OP</th>
															<th className='text-center align-middle'>Tipo</th>
															<th className='text-center align-middle'>Descripción</th>
															<th className='text-center align-middle'>Importe</th>
															<th className='text-center align-middle'>Pedido de fondos</th>
															<th className='text-center align-middle'>Orden de entrega</th>
														</tr>
													</thead>
													<tbody className='text-black-color'>
														{isNotEmptyArray(orderPayList) ? orderPayList?.map(orderPay => {
															return <>
																<tr>
																	<td className='text-center align-middle'>
																		<ActionIcon
																			size='lg'
																			id='credit-query'
																			className='mx-2'
																			toolTipText='Detalle de la Orden de Pago'
																			icon={faSearch}
																			onClick={() => onClickPayOrderDetail(orderPay)} />
																		{orderPay?.number + "/" + orderPay?.year}
																	</td>
																	<td className='text-center align-middle'>{getOrderPayTypeValueToShow(orderPay?.type)}</td>
																	<td className='text-center align-middle'>{orderPay?.description}</td>
																	<td className='text-right'>{formatterPeso.format(orderPay?.amount)}</td>
																	<td className='text-center align-middle'>
																		{orderPay?.fundRequest &&
																			<ActionIcon
																				size='lg'
																				id='credit-query'
																				className='mx-2'
																				toolTipText='Detalle del Pedido de Fondos'
																				icon={faSearch}
																				onClick={() => onClickFundRequestDetail(orderPay.fundRequest)} />
																		}
																		{orderPay?.fundRequest ? `${orderPay.fundRequest.number}/${orderPay.fundRequest.year}` : "-"}
																	</td>
																	<td className='text-center align-middle'>
																		{orderPay?.fundRequest?.deliveryOrder &&
																			<ActionIcon
																				size='lg'
																				id='credit-query'
																				className='mx-2'
																				toolTipText='Detalle de la Orden de Entrega'
																				icon={faSearch}
																				onClick={() => onClickDeliveryOrderDetail(orderPay.fundRequest.deliveryOrder)} />
																		}
																		{orderPay?.fundRequest?.deliveryOrder ? `${orderPay.fundRequest.deliveryOrder.number}/${orderPay.fundRequest.deliveryOrder.year}` : "-"}
																	</td>
																</tr>
															</>
														})
															:
															<tr>
																<td colSpan={6} className='text-center'>
																	<Alert variant='info' className='mb-0'>
																		<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
																		No hay registros
																	</Alert>
																</td>
															</tr>
														}
													</tbody>
												</Table>
											</Tab>
										</Tabs>
									</>
									:
									undefined
							}
						</Form>
					</FormProvider>
				</Card.Body>
			</Card>
		</Container>






		<DetailModal
			showPayOrderDetailModal={showPayOrderModal}
			setShowPayOrderDetailModal={setShowPayOrderModal}
			activePayOrder={activeOP}
		/>
		<FundRequestDetailModal
			showFundRequestDetailModal={showFundRequestDetailModal}
			setShowFundRequestDetailModal={setShowFundRequestDetailModal}
			activeFundRequest={activeFR}
		/>
		<DeliveryOrderDetailModal
			showDeliveryOrderDetailModal={showDeliveryOrderDetailModal}
			setShowDeliveryOrderDetailModal={setShowDeliveryOrderDetailModal}
			activeDeliveryOrder={activeDO}
		/>
	</>
};

export default ReportAffectationsByAdministrativeDocumentPage;