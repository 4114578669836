import axios from "axios";
import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, httpPost, httpPut, httpDelete, buildURLQuery } from './httpServices';

const API_BASE_URI_V1 = `${config.apis.authApi.URL}/v1`;
const API_BASE_URI_V2 = `${config.apis.authApi.URL}/v2`;
const USER_ENDPOINT_URL = '/users/filtered';
const API_PF_URI = `${config.apis.fundsRequestsApi.URL}`;
const PERSONA_URI = `${API_PF_URI}/persona`;

//User
export const listUser = async ( accessToken, Page, PageSize, FilterText, profileId, cancelToken ) => {

	const setbuildUrlQuery = {
		Page: Page || 1,
		PageSize: PageSize || 10,
		FilterText: FilterText || null,
		ProfileId: profileId || null
	};

	const queryString = buildURLQuery(setbuildUrlQuery);
	const URL = `${API_BASE_URI_V2}${USER_ENDPOINT_URL}?${queryString}`;
	const options = buildDefaultOptions(accessToken);
	options.cancelToken = cancelToken;

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		if (axios.isCancel(error)) {
			// Handle if request was cancelled
			// 499 Client Closed Request
			error.status = 499;
		}
		return error;
	}

	return response;
};

//New User
const USER_ENDPOINT_URL_POST = '/users';

export const newUser = async ( accessToken, name='', alias='', password='', email='', description='description', profileId ) => {

	const URL = `${API_BASE_URI_V2}${USER_ENDPOINT_URL_POST}?profileId=${profileId}`;
	const callbackUrl = window.location.href.replace('user-new','reset-password'); 
	const options = buildDefaultOptions(accessToken);
		
	const params = {
		name,
		alias,
		password,
		email,
		description,
		callbackUrl
    }

    let response;

	try {
		response = await httpPost( URL, params, options );
	}
	catch( error ) {
		return error?.response||error;
	}

	return response;
};

//Edit User
const USER_ENDPOINT_URL_PUT = '/users';

export const editUser = async ( accessToken, userData ) => {

	const profileId = userData?.userXApplicationXProfile?.profileId

	const URL = `${API_BASE_URI_V2}${USER_ENDPOINT_URL_PUT}?profileId=${profileId}`;
	const options = buildDefaultOptions(accessToken);
		
	const params = {
		password: userData?.password,
		id: userData?.id,
		name: userData?.name,
		alias: userData?.alias,
		description: userData?.description||'description',
		email: userData?.email,
		valid: userData?.valid
	};

    let response;
	try {
		response = await httpPut( URL, params, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

//Delete User
const USER_ENDPOINT_URL_DELETE = '/users';

export const deleteUser = async ( accessToken, id=0 ) => {

	const URL = `${API_BASE_URI_V2}${USER_ENDPOINT_URL_DELETE}/${id}`;
	const options = buildDefaultOptions(accessToken);
	
    let response;
	try {
		response = await httpDelete( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

//Profiles
const PROFILE_ENDPOINT_URL = '/profiles';

export const listProfile = async ( accessToken ) => {

	const URL = `${API_BASE_URI_V2}${PROFILE_ENDPOINT_URL}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

//Profiles New
const PROFILE_ENDPOINT_URL_POST = '/profiles';

export const newProfile = async ( accessToken, name='', permissionId=21, applicationId=4 ) => {

	const URL = `${API_BASE_URI_V2}${PROFILE_ENDPOINT_URL_POST}`;
	const options = buildDefaultOptions(accessToken);
		
	const params = {
		name,
		permissionId,
		applicationId
    }

    let response;

	try {
		response = await httpPost( URL, params, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

// Add user to profile
const POST_USER_X_APPLICATION_X_PROFILE_ENDPOINT = '/userxapplicationxprofiles';

export const postUserXApplicationXProfile = async ( accessToken, params ) => {
	const URL = `${API_BASE_URI_V1}${POST_USER_X_APPLICATION_X_PROFILE_ENDPOINT}`;
	const options = buildDefaultOptions(accessToken);

	let response;
	try {
		response = await httpPost( URL, params, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

// Edit User X Application X Profile
const PUT_USER_X_APPLICATION_X_PROFILE_ENDPOINT_URI = '/userxapplicationxprofiles';

export const editUserXApplicationXProfile = async ( accessToken, params ) => {
	const URL = `${API_BASE_URI_V1}${PUT_USER_X_APPLICATION_X_PROFILE_ENDPOINT_URI}`;
	const options = buildDefaultOptions(accessToken);

	let response;
	try {
		response = await httpPut( URL, params, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

// List Users X Application X Profile
const LIST_USERS_X_APPLICATION_X_PROFILE_ENDPOINT = '/userxapplicationxprofiles';

export const listUsersXApplicationXProfile = async ( accessToken ) => {
	const URL = `${API_BASE_URI_V1}${LIST_USERS_X_APPLICATION_X_PROFILE_ENDPOINT}`;
	const options = buildDefaultOptions(accessToken);

	let response;
	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

// List Users X Application X Profile
const USER_ID_URI = '/users';

export const getUserData = async ( accessToken, idUser ) => {
	const URL = `${API_BASE_URI_V2}${USER_ID_URI}/${idUser}`;
	const options = buildDefaultOptions(accessToken);

	let response;
	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

//Edit User Change Password
export const editUserChangePassword = async ( accessToken, userData ) => {

	const URL = `${API_BASE_URI_V2}${USER_ENDPOINT_URL_PUT}`;
	const options = buildDefaultOptions(accessToken);
		
	const params = {
		password: userData?.password,
		id: userData?.id,
		name: userData?.name,
		alias: userData?.alias,
		description: userData?.description||'description',
		email: userData?.email,
		valid: userData?.valid
	};

    let response;
	try {
		response = await httpPut( URL, params, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

//send validation email
const SEND_ID_URI = '/users/SendValidation';

export const sendValidationEmail = async ( accessToken, idUser ) => {
	const URL = `${API_BASE_URI_V2}${SEND_ID_URI}?id=${idUser}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpPost( URL, null, options );
	}
	catch( error ) {
		return error?.response||error;
	}

	return response;
};

export const getPersonaNameByCuit = async ( accessToken, cuit, withDomicilio ) => {

	const params = {
		cuit,
		withDomicilio
	};

	const queryString = buildURLQuery(params);
	
	const URL = `${PERSONA_URI}/{id}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'text');

	let response;
	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error?.response||error;
	}
	return response;
};
