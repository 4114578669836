import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Image } from 'react-bootstrap';
import { logout } from 'src/redux/login/loginActions';
import logoContaduria from 'src/assets/images/logo_gobierno_contaduria.png';
import { INITIAL_URI, LOGIN_URI } from 'src/utils/constants';
import DropdownUserControl from 'src/components/general/header/DropdownUserControl';
import DropdownPeriods from 'src/components/general/header/DropdownPeriods';
import { isNotEmptyArray } from 'src/services/validationService';
import { getActiveAlertData, getAlertActiveSet} from 'src/redux/alert/alertReducer';
import AlertMessageComponent from 'src/components/common/AlertMessageComponent';

const Header = () => {

	const dispatch = useDispatch();

	const listAlertData = useSelector(state => getActiveAlertData(state))?.records;
	const hasListAlerts = isNotEmptyArray(listAlertData);
	const setAlertActiveValue = useSelector(state => getAlertActiveSet(state));

	const handleClickLogout = () => {
		dispatch(logout());
	};

	return (
		<Container fluid>
			<div style={{
				display: 'flex',
				alignItems: 'center',
				gap: '5px',
			}} id='header'>
				<div className='mr-auto mt-2'>
					<a className='cursor-pointer' onClick={() => dispatch(push(INITIAL_URI))}>
						<Image src={logoContaduria} className='logo' />
					</a>
				</div>
				<AlertMessageComponent
					listAlertData={listAlertData}
					hasListAlerts={hasListAlerts}
					setAlertActiveValue={setAlertActiveValue}
				/>
				<div className='d-none d-md-flex justify-content-end'>
					<DropdownPeriods className='mr-2' />
					<DropdownUserControl handleClickLogout={handleClickLogout} />
				</div>
			</div>
		</Container>
	);
};

export default Header;