import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const AlertMessageComponent = props => {
	const { hasListAlerts, listAlertData, setAlertActiveValue } = props;

	return (
		<div
			style={{
				display: 'flex',
				overflowX: 'auto',
				whiteSpace: 'nowrap',
				gap: '4px',
			}}
		>
			{hasListAlerts ?
				listAlertData?.map(item => (
					<div
						key={item.id}
						className={`alert alert-${item?.level?.toLowerCase()} m-1 p-2`}
						role="alert"
						style={{
							maxWidth: '1400px',
							whiteSpace: 'nowrap',
							overflowX: 'auto',
						}}
						title={item?.message}
					>
						<FontAwesomeIcon
							className={`text-${item?.level?.toLowerCase()} mr-1`}
							icon={faBell}
						/>
						{item?.message}
					</div>
				))
				: setAlertActiveValue?.records?.map(item =>
					<tr key={item.id}>
						<td className={`alert alert-${item?.level?.toLowerCase()} m-1`} role='alert'>
							<FontAwesomeIcon className={`text-${item?.level?.toLowerCase()} mr-1`} icon={faBell} />
							{item?.message}
						</td>
					</tr>)
			}
		</div>
	);
};

export default AlertMessageComponent;