import moment from 'moment';
import { config } from 'src/env.js';
import { isNotEmptyArray } from 'src/services/validationService';
import { DEFINITIVA, OBLIGACION_PAGO } from 'src/services/affectationServices';
import { getGlobalDataSelectedPeriod } from '../redux/globalData/globalDataReducer';
import { listSubcode } from '../services/subcodeServices';

export const getCurrentYear = () => new Date().getFullYear();
const userNameSeparator = "|";


export const getCurrentPeriod = periods => {	
	let currentPeriod;
	if (isNotEmptyArray(periods)) {
		currentPeriod = periods?.find(period => period?.exerciseState === 'EXECUTION');
		if (!currentPeriod) {
			currentPeriod = periods[0];
		}
	}
	return currentPeriod;
};

export const parseIntOrUndefined = valueToParse => {
	const parsedValue = parseInt(valueToParse);
	return isNaN(parsedValue) ? undefined : parsedValue;
};

export const parseFloatOrUndefined = valueToParse => {
	const parsedValue = parseFloat(valueToParse);
	return isNaN(parsedValue) ? undefined : parsedValue;
};

export const removeLastChar = (text, char) => {
	if (text && char && typeof text === 'string' && typeof char === 'string') {
		return text.slice(0, text.lastIndexOf(char));
	}
};

export const openStreamPDFFile = streamPdf => {
	//Create a Blob from the PDF Stream
	const file = new Blob(
		[streamPdf],
		{ type: 'application/pdf' });

	//Build a URL from the file
	const fileURL = URL.createObjectURL(file);

	//Open the URL on new Window
	window.open(fileURL);
}

// Getters HTTP request headers
export const getContentTypeHeader = headers => (headers["Content-Type"] || headers["content-type"]);


export const downloadStreamFile = (response, partialFileName) => {
	// Response data
	const headers = response?.headers;
	const streamFile = response?.data;
	const mimeType = getContentTypeHeader(headers);
	

	// File attributes
	const today = new Date();
	const dateString = `${today.getFullYear()}_${today.getMonth() + 1}_${today.getDate()}`;
	const fileExtension = extractFileExtensionFromContentType(mimeType);
	let fileNamePartial;

	if (fileExtension == 'xlsx') {
		fileNamePartial = `${dateString}-${partialFileName}`;
	} else {
		fileNamePartial = `${dateString}-${partialFileName}.${fileExtension}`;
	}
	const fileName = fileNamePartial;

	// It is necessary to create a new blob object with mime-type explicitly set
	// otherwise only Chrome works like it should
	var newBlob = new Blob([streamFile], { type: mimeType })

	// IE doesn't allow using a blob object directly as link href
	// instead it is necessary to use msSaveOrOpenBlob
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(newBlob);
		return;
	}

	// For other browsers: 
	// Create a link pointing to the ObjectURL containing the blob.
	const data = window.URL.createObjectURL(newBlob);
	var link = document.createElement('a');
	link.href = data;
	link.download = fileName;
	link.click();
	setTimeout(function () {
		// For Firefox it is necessary to delay revoking the ObjectURL
		window.URL.revokeObjectURL(data);
	}, 100);
};

// Check if a date is valid
export const isValidDate = dateToValidate => {
	let retorno = false;
	if (dateToValidate) {
		const dateObj = new Date(dateToValidate);
		retorno = !isNaN(dateObj.getFullYear());
	}
	return retorno;
};

//Check organism code
export const isValidateOrganismCode = (value)=> {
	let intValue = parseInt(value);
	return !!(intValue) && 1000 <= intValue && intValue <= 9999;
}

// Converts a date into strin with format 'DD/MM/YYYY'
export const dateToStringFormatedToShowARG = dateToConvert => {
	let retorno;
	if (isValidDate(dateToConvert)) {
		const theDateObj = new Date(dateToConvert);
		const theYear = theDateObj.getFullYear();
		let theMont = (theDateObj.getMonth() + 1)?.toString();
		theMont = theMont.length == 1 ? '0' + theMont : theMont;
		let theDay = theDateObj.getDate()?.toString();
		theDay = theDay.length == 1 ? '0' + theDay : theDay;
		retorno = `${theDay}/${theMont}/${theYear}`;
	}
	return retorno;
};

// Converts a date into strin with format 'DD/MM/YYYY HH:MM:SS'
export const dateToStringFormatedToShowARGWithHour = dateToConvert => {
	let retorno;
	if (isValidDate(dateToConvert)) {

		const theDateObj = new Date(dateToConvert);
		//Date
		const theYear = theDateObj.getFullYear();
		let theMont = (theDateObj.getMonth() + 1)?.toString();
		theMont = theMont.length == 1 ? '0' + theMont : theMont;
		let theDay = theDateObj.getDate()?.toString();
		theDay = theDay.length == 1 ? '0' + theDay : theDay;
		//Hours
		let hours = theDateObj.getHours();
		let theHours = hours < 10 ? '0' + hours : hours;
		let minutes = theDateObj.getMinutes();
		let theMinutes = minutes < 10 ? '0' + minutes : minutes;
		let seconds = theDateObj.getSeconds();
		let theSeconds = seconds < 10 ? '0' + seconds : seconds;


		retorno = `${theDay}/${theMont}/${theYear} ${theHours}:${theMinutes}:${theSeconds}`;
	}
	return retorno;
};

// Converts a date into strin with format 'YYYY/MM/DD'
export const dateToStringFormatedToShowUSA = dateToConvert => {
	let retorno;
	if (isValidDate(dateToConvert)) {
		const theDateObj = new Date(dateToConvert);
		const theYear = theDateObj.getFullYear();
		let theMont = (theDateObj.getMonth() + 1)?.toString();
		theMont = theMont.length == 1 ? '0' + theMont : theMont;
		let theDay = theDateObj.getDate().toString();
		theDay = theDay.length == 1 ? '0' + theDay : theDay;
		retorno = `${theYear}-${theMont}-${theDay}`;
	}
	return retorno;
};

// Converts a date into strin with format 'YYYY-MM-DD' return 'DD/MM/YYYY'
export const dateNeutralFormatedToShowARG = dateToConvert => {
	let retorno;
	if (isValidDate(dateToConvert)) {
		retorno = dateToConvert.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1');
	}
	return retorno;
};


// Control date is correct
export const dateToControl = dateToControl => {
	let retorno;
	if (isValidDate(dateToControl)) {
		let dateControlMinimo = '1970-01-01';
		//date today
		let date = new Date();
		let dateToday = (date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate());

		if (dateToControl && dateToControl < dateControlMinimo) {
			retorno = true;
		}
		else if (dateToControl && dateToControl > dateToday) {
			retorno = true;
		}
	}
	return retorno;
};

/*	Verifica si la fecha es valida y si se encuentra entre 1970 y la fecha actual. 
	@param string dateToControl Is required
*/
export const isQualifiedValidDate = dateToControl => {
	if (!dateToControl) return false;
	let dateControlMinimo = '1970-01-01';

	let validDate = moment(dateToControl).isValid();
	let isAfter1970 = moment(dateToControl).isAfter(dateControlMinimo);
	let isAfterToday = moment(dateToControl).isAfter();

	return validDate && isAfter1970 && !isAfterToday;
};



//Formater number in Formater Money Peso AR
export const formatterPeso = new Intl.NumberFormat('es-CO', {
	style: 'currency',
	currency: 'COP',
	minimumFractionDigits: 2
})

//Formater number in Formater Money Peso AR two decimals
export const formatterPesoTwoDecimals = new Intl.NumberFormat('es-CO', {
	style: 'currency',
	currency: 'COP',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2
})

export const extractFileExtensionFromContentType = mimeType => {
	switch (mimeType) {
		case 'application/pdf': {
			return 'pdf';
		}
		case 'application/vnd.ms-excel': {
			return 'xls';
		}
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
			return 'xlsx';
		}
		case 'image/png': {
			return 'png';
		}
		case 'image/jpeg': {
			return 'jpg';
		}
		case 'text/plain': {
			return 'txt';
		}
		case 'application/zip' : {
			return 'zip';
		}
		default: {
			return 'txt';
		}
	}
};

// Builds the "Credit number"(Numero de partida) froms a credit object
export const getCreditNumber = creditData => {
	const serviceNumber = creditData.service?.number;
	const jurisdicctionNumber = creditData.organization?.jurisdiction?.number;
	const organizationNumber = creditData.organization?.number;
	const characterCode = creditData.character?.code;
	const accountNumber = creditData.account?.number;
	const purposeNumber = creditData.functionality?.purpose?.number;
	const functionalityNumber = creditData.functionality?.number;

	const sectionNumber = creditData.partialBudget?.principalBudget?.sector?.section?.number;
	const sectorNumber = creditData.partialBudget?.principalBudget?.sector?.number;
	const principalBudgetNumber = creditData.partialBudget?.principalBudget?.number;
	const partialBudgetNumber = creditData.partialBudget?.number;
	return `${serviceNumber}-${jurisdicctionNumber}-${organizationNumber}-${characterCode}-${accountNumber}-${purposeNumber}-${functionalityNumber}-${sectionNumber}-${sectorNumber}-${principalBudgetNumber}-${partialBudgetNumber}`;
};

// Returns an array with current year and three previous year.
export const getAdministrativeDocumentYearsOptions = (yearSelected = null) => {
	const currentYear = yearSelected ?? getCurrentYear();
	const yearsOptions = [currentYear];
	for (let i = 0; i < 43; i++) {
		yearsOptions.push(currentYear - (i + 1));
	}
	return yearsOptions;
};

// Returns an array with current year and seven previous year.
export const getNewAffectationYearsOptions = () => {
	const currentYear = getCurrentYear();
	const yearsOptions = [currentYear];
	for (let i = 0; i < 14; i++) {
		yearsOptions.push(currentYear - (i + 1));
	}
	return yearsOptions;
};

// Return an array with the current year and a specified number of previous years
export const getYearsOptionsByParam = (minimumYear, selectedPeriod) => {

	const currentYear = selectedPeriod;
	const yearsOptions = [currentYear];
	const numberOfYears = currentYear - minimumYear;

	for (let i = 0; i < numberOfYears; i++) {
		yearsOptions.push(currentYear - (i + 1));
	}
	
	return yearsOptions;
};

export const getExerciseTypeValueToShow = value => {
	switch (value) {
		case 'FORMULATION':
			return 'Formulación';
		case 'EXECUTION':
			return 'Ejecución';
		case 'COMPLEMENTARY':
			return 'Complementario';
		case 'CLOSED':
			return 'Cerrado';
		default:
			return;
	}
};

export const getOrderPayTypeValueToShow = value => {
	switch (value) {
		case 'ORDEN_DE_PAGO':
			return 'Orden de pago';
		case 'ORDEN_DE_PAGO_DE_HABERES':
			return 'Orden de pago de haberes';
		case 'ORDEN_DE_PAGO_INSTITUCION_DE_FONDOS_PERMANENTES':
			return 'Orden de Pago institución de fondos permanentes';
		case 'ORDEN_DE_CARGO':
			return 'Orden de cargo';
		case 'ORDEN_DE_PAGO_CONTRA_ENTREGA':
			return 'Orden de pago contra contra entrega';
		case 'ORDEN_DE_PAGO_REMESAS':
			return 'Orden de pago remesas';
		case 'ORDEN_DE_PAGO_DEPOSITO_INDEBIDO':
			return 'Orden de pago deposito indebido';
		case 'ORDEN_DE_PAGO_DE_PROVEEDORES':
			return 'Orden de pago de proveedores';
		default:
			return;
	}
};

export const getFundRequestTypeValueToShow = value => {
	switch (value) {
		case 'PEDIDO_DE_FONDOS':
			return 'Pedido de Fondo';
		case 'PEDIDO_DE_FONDOS_MANUAL':
			return 'Pedido de Fondo Manual';
		case 'PEDIDO_DE_FONDOS_DE_PROVEEDORES':
			return 'Pedido de Fondos de Proveedores';
		case 'PEDIDO_DE_FONDOS_DE_HABERES':
			return 'Pedido de Fondos de Haberes';
		case 'PEDIDO_DE_FONDOS_DE_EROGACIONES_FIGURATIVAS':
			return 'Pedido de Fondos de Erogaciones Figurativas';
		case 'PEDIDO_DE_FONDOS_DE_INSTITUCION_DE_FONDOS_PERMANENTES':
			return 'Pedido de Fondo de Institucion de Fondos Permanentes';
		case 'PEDIDO_DE_FONDOS_DE_REINTEGRO_TESORERIA_GENERAL':
			return 'Pedido de Fondos de Reintegro de la Tesoreria General';
		case 'PEDIDO_DE_FONDOS_DE_CONTADO_CONTRA_ENTREGA':
			return 'Pedido de Fondo de Contado Contra Entrega';
		case 'PEDIDO_DE_FONDOS_DE_ANTICIPO_DE_CUENTA_RENTAS_GENERALES':
			return 'Pedido de Fondos de Anticipo de Cuenta de Rentas Generales';
		case 'PEDIDO_DE_FONDOS_DE_ANTICIPO_DE_CUENTA_RENTAS_GENERALES_HABERES':
			return 'Pedido de Fondos de Anticipo de Cuenta de Rentas Generales Haberes';
		default:
			return;
	}
};

export const getFundRequestStateValueToShow = value => {
	switch (value) {
		case 'BORRADOR':
			return 'Borrador';
		case 'ACTIVO_PENDIENTE_DE_AUTORIZACION':
			return 'Activo pendiente de autorizacion';
		case 'PEDIDO_DE_FONDOS_DE_PROVEEDORES':
			return 'Pedido de Fondos de Proveedores';
		case 'ANULADO':
			return 'Anulado';
		case 'AUTORIZADO_PENDIENTE_DE_PAGO':
			return 'Autorizado pendiente de pago';
		case 'ENVIADO_TESORERÍA_GENERAL':
			return 'Enviado tesoreria general';
		case 'PAGADO':
			return 'Pagado';
		default:
			return;
	}
};

export const fundFormsReadyToDisplay = formsArr => {
	const formsAllowToShow = ['PEDIDO_DE_FONDOS', 'PEDIDO_DE_FONDOS_DE_PROVEEDORES', 'PEDIDO_DE_FONDOS_DE_HABERES', 'PEDIDO_DE_FONDOS_MANUAL'];
	return formsArr?.filter((form)=> formsAllowToShow?.includes(form));
};

export const fundRequestTypesForFundTransferManagerProfile = formsArr => {
	const formsAllowToShow = ['PEDIDO_DE_FONDOS_MANUAL'];
	return formsArr?.filter((form) => formsAllowToShow?.includes(form));
};

export const stateFormsReadyToDisplay = stateformsArr => {
	const formsAllowToShow = ['BORRADOR', 'ACTIVO_PENDIENTE_DE_AUTORIZACION', 'ANULADO', 'AUTORIZADO_PENDIENTE_DE_PAGO', 'ENVIADO_TESORERÍA_GENERAL', 'PAGADO'];
	return stateformsArr?.filter((form)=> formsAllowToShow?.includes(form));
};

export const orderFormsReadyToDisplay = formsArr => {
	const formsAllowToShow = ['ORDEN_DE_PAGO', 'ORDEN_DE_PAGO_DE_PROVEEDORES', 'ORDEN_DE_PAGO_DE_HABERES'];
	return formsArr?.filter((form)=> formsAllowToShow?.includes(form));
};

export const numberNegativeRed = item => {
	return `${item < 0 ? 'text-danger' : ''}`;
}

//Formater Separdor de Miles
export const formaterNumberThousandsSeparator = valueNumber => {

	let retorno;

	var entrada = valueNumber?.split(','),
		parteEntera = entrada[0].replace(/\./g, ''),
		parteDecimal = entrada[1],
		salida = parteEntera?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

	retorno = salida + (parteDecimal !== undefined ? ',' + parteDecimal : '');

	return retorno;
};

// Focus Input
export const focusSelectInputByElementID = focusID => {
	//Focus
	document.getElementById(focusID)?.focus();
	//Select
	document.getElementById(focusID)?.select();
};

export const objectHasValue = (obj, value) => {
	const searchTerms = new RegExp(value, 'i');
	return Object.keys(obj).some(function (key) {
		return searchTerms.test(obj[key]);
	});
};
/**
 * Generates a random password
 *
 * @param numLc Number of lowercase letters to be used (default 4)
 * @param numUc Number of uppercase letters to be used (default 4)
 * @param numDigits Number of digits to be used (default 4)
 */
export const generatePassword = function (numLc, numUc, numDigits) {
	numLc = numLc || 4;
	numUc = numUc || 4;
	numDigits = numDigits || 4;


	var lcLetters = 'abcdefghijklmnopqrstuvwxyz';
	var ucLetters = lcLetters.toUpperCase();
	var numbers = '0123456789';

	var getRand = function (values) {
		return values.charAt(Math.floor(Math.random() * values.length));
	}

	//+ Jonas Raoni Soares Silva
	//@ http://jsfromhell.com/array/shuffle [v1.0]
	function shuffle(o) { //v1.0
		for (var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
		return o;
	};

	var pass = [];
	for (var i = 0; i < numLc; ++i) { pass.push(getRand(lcLetters)) }
	for (var i = 0; i < numUc; ++i) { pass.push(getRand(ucLetters)) }
	for (var i = 0; i < numDigits; ++i) { pass.push(getRand(numbers)) }

	return shuffle(pass).join('');
}

export const SumCollectionAmounts = (dataCollection, key = 'amount') => {
	let retorno = 0;
	dataCollection?.map((item) => (
		retorno = retorno + (item[key] || 0)
	))
	return retorno;
};
export const SumCollectionAmountsOP = (dataCollection) => {
	let retorno = 0;
	dataCollection?.map((item) => (
		retorno = retorno + (item.presupuestoResponse.amount || 0)
	))
	return retorno;
};

export const calcTotalImportes = (fundRequestDetails, fundRequest) => {
	if (!fundRequestDetails || fundRequestDetails.some(i => !i.paymentOrder)) return 0;
	return fundRequestDetails?.reduce((total, item) => {
		return total + item?.paymentOrder?.details?.reduce((totalDetail, itemDetail) => totalDetail + itemDetail?.presupuestoResponse.amount, 0);
	}, fundRequest ?? 0);
};

// Get audit attribute name
export const getAuditAttributeName = value => {
	switch (value) {
		case 'pending':
			return 'Pendiente';
		case 'error':
			return 'Error';
		case 'ready':
			return 'Generado';
		case 'processing':
			return 'Procesando';
		default:
			return;
	}
};

export const getMovementsAmount = movements => {
	return movements.reduce((total, item) => {
		switch (item.affectationStatus.id) {
			case DEFINITIVA:
				total += item.amount;
				break;
			case OBLIGACION_PAGO:
				total -= item.amount;
				break;
		}
		return total;
	}, 0);
};

export const buildAmount = list => {
	return list.map(i => {
		i.amount = i.amountInitial = (i.balance.currentBalance);
		return i;
	});
};

//Por ahora obtenemos info de la partida a traves de budgetNumber, mas adelante vendra en la api  
export const buildBudgetNumber = list => {
	return list.map(i => {
		const partidaData = (i?.subCodeCompleteNumber || i?.completeNumber)?.split("-")
		return { ...i, partidaData };
	})
}
export const isInExerciseRange = (exercise, dateFrom, dateTo) => {
	return (exercise?.periods[0]?.startDate <= dateFrom) && (dateTo <= exercise?.periods[13]?.endDate)
};

//pensado para fecha fin
export const isDateBetweenRange = (exercise, date) => {
	return isInExerciseRange(exercise, date, date);
};

export const joinLastNameName = (lastName, name) => {
	return [lastName, name].filter(Boolean).join(userNameSeparator);
}

export const buildFullName = (name) => {
	return name.split(userNameSeparator).join(" ");
};

export const userNameData = (name = "") => {
	let nameParts = name.split(userNameSeparator);
	return {
		lastName: nameParts[0],
		firstName: nameParts[1]
	};
};

//Clear objects methods
export const getClearObject = obj => {
	return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v || v === 0 ));
}

export const getClearObjectAlt = obj => {
	return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v ));
}

//
export const shortlistedService = (options) => {	
	if (options.length == 1) {
		return true;
	} else {
		return false;
	}
}

export const isTokenExpired = (token) => {
	const expiresDate = token;
	const expireDate = new Date(expiresDate);
	const todayDate = new Date();
	const res = ( !expiresDate || (expireDate < todayDate) );
	return res;
};

export const isValidString = (text) => {
		
	if(text?.trim() != ''){
		return true;
	}else{
		return false;
	}
}

export const getAlertTypeValueToShow = value => {
	switch (value) {
		case 'DANGER':
			return 'Crítico';
		case 'WARNING':
			return 'Advertencia';
		case 'INFO':
			return 'Información';
		default:
			return value;
	}
};

// Convert string to Boolean
export const convertStringToBoolean = value =>{
	switch(value.toLowerCase().trim()){
		case "yes": case "true": case "1": return true;
		case "no": case "false": case "0": case null: return false;
		default: return Boolean(value);
	}
}

export const removeAccents = (str) => {
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const multiFilter = (data, params) => {
	const filters = getClearObject(params);
	const dataUpdate = data.filter((item) => {
	let filteredData =[];
	for (const key in filters) {
		filteredData.push(removeAccents(item[key].toLowerCase()).includes(filters[key].toLowerCase()))
	}
	if(!filteredData.includes(false)) return filteredData;
	});
	return dataUpdate;
};

export const checkIsEmptyObj = (obj) => {
    return Object.keys(obj).length === 0;
}

export const containsObject = (arr, property, value)=>{
	return clearNullValuesFromArr(arr)?.some(element => element[property] === value);
}

export const containsIdValidation = (arr, item)=>{
	return arr?.some(obj => obj.fundRequest.id === item.id);
}

export const checkContainsArr = (arr, value)=>{
	return arr?.some(obj => obj?.number === value);
}

export const checkContainsArrDocAdmin = (arr, position, item)=>{
	switch (position) {
		case 'year':
			return arr?.some(obj => obj?.administrativeDocument?.year === item)	
		case 'codeOrganism':
			return arr?.some(obj => obj?.administrativeDocument?.codeOrganism === item)	
		case 'number':
			return arr?.some(obj => obj?.administrativeDocument?.number === item)	
		case 'nroExpediente':
			return arr?.some(obj => obj?.nroExpediente === item)	
		default:
			break;
	}
	
}


export const paginatorForData = (items, activePage, perPage) =>{
	let page = activePage || 1;
	let per_page = perPage || 10;
	let offset = (page - 1) * per_page;
	let paginatedItems = items.slice(offset).slice(0, perPage);
	let totalPages = Math.ceil(items.length / per_page);

	return {
		page: page,
		per_page: per_page,
		pre_page: page - 1 ? page - 1 : null,
		total_pages: totalPages,
		data: paginatedItems
	};
}

export const getMsjAFIP = (params, number = 0) => {
	switch (params) {
		case 'AFIP':
			return 'Validado por AFIP'
		case 'PROVEEDORES':
			return 'Validado por PROVEEDORES, número: '+ number	
		default:
			return 'No se ha podido validar con las fuentes oficiales, tome los recaudos para validar manualmente'
	}
}

export const dataPeriodsContructor = (periodSelected, periodsData) =>{

	let year = periodSelected?.year;

	const matchYearId = (periodsData, year) =>{
		const arr = periodsData;
		for (let i = 0; i < arr?.length; i++) {
			if(Object.values(arr[i]).includes(year)) {
				return arr[i].id;
			}
		}
	};

	const yearsBackWards = 3;

	let arr = [];

	for (let i = 0; i < yearsBackWards; i++) {
		if(!arr.length){
			arr.push({visibleName: "Partidas del ejercicio", period_rp_id:null, value: "Partida del Ejercicio"});
		}else{
			if(matchYearId(periodsData, year)){
				arr.push({visibleName: `Residuos Pasivos ${year}`, period_rp_id:matchYearId(periodsData, year), value: `Residuo Pasivo ${year}`, periodRpName: year});
			}
		}
		year--;
	}
	arr.push({visibleName: "Residuos Pasivos", isPassiveRemaining:1, value: "Residuos Pasivos"});
	return arr;
}

export const dataPassiveRemainingPeriodsConstructor = (periodSelected, periodsData) => {

	let year = periodSelected?.year -1;

	const matchYearId = (periodsData, year) =>{
		const arr = periodsData;
		for (let i = 0; i < arr?.length; i++) {
			if(Object.values(arr[i]).includes(year)) {
				return arr[i].id;
			}
		}
	};

	const yearsBackWards = 2;

	let arr = [];

	arr.push({visibleName: "Residuos Pasivos", isPassiveRemaining:1, value: "Residuos Pasivos"});
	for (let i = 0; i < yearsBackWards; i++) {
		if(matchYearId(periodsData, year)){
			arr.push({visibleName: `Residuos Pasivos ${year}`, period_rp_id:matchYearId(periodsData, year), value: `Residuo Pasivo ${year}`, periodRpName: year});
		}
		year--;
	}
	
	return arr;
};

export const dataPeriodsContructorCreditModification = (periodSelected, periodsData) =>{

	let year = periodSelected?.year;

	const matchYearId = (periodsData, year) =>{
		const arr = periodsData;
		for (let i = 0; i < arr?.length; i++) {
			if(Object.values(arr[i]).includes(year)) {
				return arr[i].id;
			}
		}
	};

	const yearsBackWards = 3;

	let arr = [];

	for (let i = 0; i < yearsBackWards; i++) {
		if(!arr.length){
			arr.push({visibleName: "Partidas del ejercicio", period_rp_id:null, value: "Partida del Ejercicio"});
		}else{
			if(matchYearId(periodsData, year)){
				arr.push({visibleName: `Residuos Pasivos ${year}`, period_rp_id:matchYearId(periodsData, year), value: `Residuo Pasivo ${year}`, periodRpName: year});
			}
		}
		year--;
	}

	return arr;
}

export const mFormat = (date, format = 'DD/MM/YYYY') =>{
	return moment(date).format(format);
}

export const PFLabelConstructor = (tipo) => {
	const auxLabel = tipo === 'PEDIDO_DE_FONDOS_DE_CONTADO_CONTRA_ENTREGA' ? 'Previsión' : 'Pago';
	return {
		title: `Órdenes de ${auxLabel}`,
		label: `Orden de ${auxLabel}`,
		required: `Debe seleccionar al menos una orden de ${auxLabel}.`,
	}
};

export const selectedOrderTypeToSend = {
    PEDIDO_DE_FONDOS: 'ORDEN_DE_PAGO',
	PEDIDO_DE_FONDOS_DE_PROVEEDORES: 'ORDEN_DE_PAGO_DE_PROVEEDORES',
	PEDIDO_DE_FONDOS_DE_HABERES: 'ORDEN_DE_PAGO_DE_HABERES',
};

export const numberFormatParser = (dataFromForm, propertiesToFormat) =>{
	return Object.keys(dataFromForm)?.filter((key)=> propertiesToFormat?.includes(key)).reduce((accumulator, key) => {
		return {...accumulator, [key]: parseFloat(dataFromForm[key].replaceAll('.', '').replace(',','.'))};
	}, {});
};

export const removeDuplicateElements = (dataArr, key) => {
	const setOfUniqueIds = new Set();
	return dataArr?.filter(item => {
		const hasDuplicated = setOfUniqueIds.has(key ? item?.[key] : item );
		setOfUniqueIds.add(key ? item?.[key] : item);
		if (!hasDuplicated) return true;
	});
};

export const clearWhiteSpaces = (dataToClear, listToAllowClear) =>{
	return Object.keys(dataToClear)?.filter((key)=> listToAllowClear?.includes(key)).reduce((accumulator, key) => {
		return {...accumulator, [key]: dataToClear[key]?.replace(/\s/g, '')};
	}, {});
};

export const clearDTOFromEmptyData = (obj) => {
	const checker = (obj) => {
		if(typeof obj === "object") return Object.keys(obj)?.length === 0;
		return false;
	}
	return Object.fromEntries(Object.entries(getClearObject(obj))?.filter(([_, v]) => !checker(v)))
}
export const clearNullValuesFromArr = (arr) =>{
	return arr?.filter((element) => element !== null && element !== undefined);
};

export const singleNumberFormatter = (numb) => {
	return parseFloat(numb?.replaceAll('.', '').replace(',','.'))
};

export const budgetFiltersParamsConstructor = (params, accessToken, state) =>{
	const promises =[]
	for (let i = 0; i < params.length; i++) {
		let flag = 0;
		let paramsToSend = {
			filter: {
				period_id: getGlobalDataSelectedPeriod( state )?.id,
				service_number: params[i][flag++],
				jurisdiction_number: params[i][flag++],
				organization_number: params[i][flag++],
				character_number: params[i][flag++],
				account_number: params[i][flag++],
				purpose_number: params[i][flag++],
				functionality_number: params[i][flag++],
				section_number: params[i][flag++],
				sector_number: params[i][flag++],
				principal_budget_number: params[i][flag++],
				partial_budget_number: params[i][flag++],
				code_number: params[i][flag++],
				sub_code_number: params[i][flag++]
			},
			page: 1,
			pageSize: 5
		};
		promises.push(listSubcode(accessToken, paramsToSend))
	}
	return promises;
};

export const removeUnuselessObjProperties = (obj, list) => {
	list?.forEach(el => {
    obj?.hasOwnProperty(el) && delete obj[el]
	});
};

export const removeUnuselessObjPropertiesFromArray = (arr, list) => {
	arr?.forEach((item)=>removeUnuselessObjProperties(item, list))
};

export const removeUnuselessObjPropertiesFromArrayNestedObj = (arr, list) => {
	arr?.forEach((item)=> {
		for (const property in item) {
			item[property] === null && delete item[property] === null
			removeUnuselessObjProperties(item[property], list)
		}
	})
};

export const onKeyDownEnter = event => {
	var keycode = event.keyCode;
	if (keycode == 13) event.preventDefault();
};

export const organismsFilter = organismList => {
	return organismList?.map(character => ({
		id: character.id,
		codeBudget: character.codeBudget,
		name: character?.name,
		jurisdiction: character?.jurisdiction,
		organismClassifier: character?.organismClassifier,
		nameToShow: character?.codeBudget + ' - ' + character?.jurisdiction?.name + ' - ' + character?.name
	})).sort((a, b) => a.codeBudget - b.codeBudget);
};