import React, { useEffect, useState } from 'react';
import { useForm, useFormContext, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Button, FormGroup, Row, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faSave } from '@fortawesome/free-solid-svg-icons';
import { getClearObject, getFundRequestTypeValueToShow, fundFormsReadyToDisplay, fundRequestTypesForFundTransferManagerProfile } from 'src/utils/utils';
import { tryPostFundRequests, tryGetTypeListFundRequests } from 'src/redux/fundRequests/fundRequestsActionCreator';
import { getFundRequestTypesData, getFundRequestTypesIsFetching, getFundRequestsCreateIsFetching, getFundRequestManualItems } from 'src/redux/fundRequests/fundRequestsReducer';
import { setTypeListFundRequest, clearTypeListFundRequests, setFundRequestsToEdit, clearFundRequestManualItems } from 'src/redux/fundRequests/fundRequestsActions.js';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { FUND_REQUESTS_LIST } from 'src/utils/constants';
import { getSelectedFundRequestType } from 'src/redux/fundRequests/fundRequestsReducer';
import PDFormFactory from 'src/components/forms/fundRequest/PDFormFactory';
import AppLoading from 'src/components/common/AppLoading';
import { figurativeRegistrationMissing } from 'src/utils/label';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';
import { getProfileName } from 'src/redux/login/loginReducer';

const FundRequestsNewPage = () => {

	const dispatch = useDispatch();
	const hookFormMethods = useForm();
	const { handleSubmit } = hookFormMethods;

	const [selectedItems, setSelectedItems] = useState([]);
	const [itemsRelated, setItemsRelated] = useState([]);
	const [admServiceId, setAdmServiceId] = useState('');
	const [bankAccountId, setBankAccountId] = useState('');
	const [fundRequestTypeState, setFundRequestTypeState] = useState('');
	const [ordenesAsociadas, setOrdenesAsociadas] = useState([]);
	const profileName = useSelector(state => getProfileName(state));

	const fundTransferManagerProfile = 'Responsable de transferencia de fondos';
	const fundRequestManualType = fundRequestTypeState === 'PEDIDO_DE_FONDOS_MANUAL';

	Object.assign(hookFormMethods, {
		admServiceId,
		setAdmServiceId,
		bankAccountId,
		setBankAccountId,
		selectedItems,
		setSelectedItems,
		itemsRelated,
		setItemsRelated,
		ordenesAsociadas,
		setOrdenesAsociadas,
	});

	const isFetching = useSelector(state => getFundRequestsCreateIsFetching(state));
	const selectedFundRequestType = useSelector(state => getSelectedFundRequestType(state));
	const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
	const fundRequestTypes = useSelector(state => getFundRequestTypesData(state));
	const fundRequestManualItems = useSelector(state => getFundRequestManualItems(state));
	const isFetchingPFTypes = useSelector(state => getFundRequestTypesIsFetching(state));

	const manualInputs = fundRequestManualItems ? fundRequestManualItems : undefined;
	const hasFundRequestManualItems = fundRequestManualItems?.length > 0 ? true : false;
	const disableFundRequestManualSaved = fundRequestManualType && !hasFundRequestManualItems;
	const disabledSaveWithoutFigurativeRegister = !selectedItems?.filter((item) => item?.paymentOrder?.hasFigurativeExpenditure)
		.every((b) => b?.figurativeExpenditures)

	const fundRequestTypesToDisplay = (profileParam) => {
		switch (profileParam) {
			case fundTransferManagerProfile: {
				return fundRequestTypesForFundTransferManagerProfile(fundRequestTypes);
			}
			default: {
				return fundFormsReadyToDisplay(fundRequestTypes)
			}
		};
	};

	const onClickCancelButton = () => {
		dispatch(push(FUND_REQUESTS_LIST));
	};

	const onSelectFundRequestType = v => {
		const params = v.target?.value;
		cleanUp();
		dispatch(setTypeListFundRequest(params));
		setFundRequestTypeState(params);
	};

	const cleanUp = () => {
		dispatch(clearTypeListFundRequests());
		dispatch(clearFundRequestManualItems());
		dispatch(setFundRequestsToEdit());
		setAdmServiceId();
		setSelectedItems([]);
		setItemsRelated([]);
	};

	useEffect(() => {
		dispatch(tryGetTypeListFundRequests());
		return () => {
			cleanUp();
		}
	}, []);

	const onSubmitForm = (data) => {
		let details = selectedItems;
		const fundRequestDto = {
			administrativeServiceId: admServiceId,
			bankAccount: {
				id: parseInt(data?.bankAccount)
			},
			details,
			expiredDate: data?.expire_date,
			fundRequestDate: data?.date,
			description: data?.asunto,
			year: globalSelectedPeriod?.year,
			fundRequestType: selectedFundRequestType,
			manualInputs,
			administrativeDocument: {
				year: data?.organismYear,
				number: data?.organismNumber,
				codeOrganism: data?.organismCode,
				title: "Orden de pago"
			},
		};
		const filterFundRequestDto = getClearObject(fundRequestDto);
		dispatch(tryPostFundRequests(filterFundRequestDto)).then(response => {
			if (response?.status > 199 && response?.status < 300) {
				dispatch(push(FUND_REQUESTS_LIST));
			}
		})
	}

	return <>
		<Container fluid>
			<Card className='mb-3'>
				<Card.Header className='d-flex justify-content-between'>
					<h1 className="h6 mt-1 mb-0">Nuevo Pedido de Fondos</h1>
					<a className='text-white' target="_blank" href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/safi2-operador-tesoreria/#ejecuci%C3%B3n-financiera--pedido-de-fondos'>
						<FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
						<small>Ver manual de uso</small>
					</a>
				</Card.Header>
				<Card.Body>
					<span className='text-danger d-flex mandatory-label text-right font-weight-bold font-italic'>
						<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
						Obligatorio
					</span>
					<FormProvider {...hookFormMethods} >
						<Form onSubmit={handleSubmit(onSubmitForm)} className='text-black-color mt-5'>
							<FormGroup as={Row}>
								<Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Tipo Pedido de Fondo:
								</Form.Label>
								<Col sm='4'>
									<Form.Control
										as="select"
										name="fundRequestType"
										onChange={onSelectFundRequestType}
										value={fundRequestTypeState}
										required >
										<option value={''}>Seleccione una opcion...</option>
										{
											fundRequestTypesToDisplay(profileName)?.map((item, idx) => (
												<option value={item} key={idx}>
													{getFundRequestTypeValueToShow(item)}
												</option>
											))
										}
									</Form.Control>
								</Col>
							</FormGroup>
							{fundRequestTypeState &&
								<PDFormFactory formName={fundRequestTypeState} />
							}
							<Alert className='mb-0 text-center' variant='danger' show={disabledSaveWithoutFigurativeRegister}>
								{figurativeRegistrationMissing}
							</Alert>
							<Form.Group className='my-5 d-flex justify-content-around text-white-color'>
								<Button variant='primary' size='lg' onClick={onClickCancelButton}>
									Cancelar
								</Button>
								<Button type='submit' variant='success' size='lg' disabled={disabledSaveWithoutFigurativeRegister || disableFundRequestManualSaved}>
									Guardar
									<FontAwesomeIcon className='ml-3' icon={faSave} />
								</Button>
							</Form.Group>
						</Form>
					</FormProvider>
				</Card.Body>
			</Card>
		</Container>
		<AppLoading isLoading={isFetching || isFetchingPFTypes} />
	</>
}

export default FundRequestsNewPage;