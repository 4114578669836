import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from 'src/components/general/FormFieldError';
import moment from 'moment';
import { number, numberAbbreviation, date } from 'src/utils/label';
import { tryListServicesByUserAndExercise } from "src/redux/administrativeService/administrativeServiceActionCreator";
import { tryGetListUserProfile } from 'src/redux/user/userActionCreator';
import { getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { NumericFormat } from 'react-number-format';
import InputCuitForm from 'src/components/common/InputCuitForm';
import InputOrganismForm from 'src/components/common/InputOrganismForm';
import InputServiceForm from 'src/components/common/InputServiceForm';
import InputLegalInstrumentForm from 'src/components/common/InputLegalInstrumentForm';
import InputContratacionForm from '../../common/InputContratacionForm';
import { faExclamationTriangle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const ProvisionOrderForm = ({ provisionOrder }) => {

    const dispatch = useDispatch();

    const { register, errors } = useFormContext();
    const [currentYear, setCurrentYear] = useState(formData?.fiscalYearId);

    const [formData, setFormData] = useState({
        administrativeServiceId: provisionOrder?.administrativeServiceId || '',
        orderNumber: provisionOrder?.number || '',
        acquisitionType: provisionOrder?.acquisitionType || '',
        acquisitionNumber: provisionOrder?.acquisitionNumber || '',
        acquisitionYear: provisionOrder?.acquisitionYear || '',
        initiatorText: provisionOrder?.initiator || '',
        destinationText: provisionOrder?.destination || '',
        deliveryPlaceText: provisionOrder?.deliveryLocation || '',
        denominationText: '',
        validExpedient: false,
        organismCode: provisionOrder?.administrativeDocument?.codeOrganism || '',
        organismNumber: provisionOrder?.administrativeDocument?.number || '',
        organismYear: provisionOrder?.administrativeDocument?.year || '',
        validLegalInstrument: false,
        legalInstrumentType: provisionOrder?.legalInstrument?.legalInstrumentType?.name || '',
        legalInstrumentNumber: provisionOrder?.legalInstrument?.number || '',
        legalInstrumentYear: provisionOrder?.legalInstrument?.year || '',
        legalInstrumentDate: provisionOrder?.legalInstrument?.date || '',
        deadlineAmount: provisionOrder?.deadlineAmount || '',
        deadlineTimeType: provisionOrder?.deadlineTimeType || '',
        observations: provisionOrder?.observations,
        fiscalYearId: provisionOrder?.year || '',
    });

    // Validations messages
    const whiteSpaceError = 'El campo contiene solo espacios en blanco. Ingrese un texto válido.'
    const numberRequiredMsg = 'Debe ingresar un número';
    const fiscalYearIdRequiredMsg = 'Debe seleccionar un año.';
    const dateRequiredMsg = 'Debe ingresar una fecha dentro del periodo';
    const initiatorRequiredMsg = 'Debe ingresar el iniciador.'
    const destinationRequiredMsg = 'Debe ingresar el destino.'
    const deliveryPlaceRequiredMsg = 'Debe ingresar el lugar de entrega.'
    const amountRequiredMsg = 'Debe ingresar la Cantidad.'
    const deadlineTimeTypeMsg = 'Debe ingresar Día / Días hábiles / Semana / Mes'
    const acquisitionTypeMsg = 'Debe seleccionar el tipo de contratación'
    const numberValidationObj = { required: numberRequiredMsg };
    const fiscalYearIdValidationObj = { required: fiscalYearIdRequiredMsg };
    const dateValidationObj = { required: dateRequiredMsg };
    const initiatorValidationObj = { required: initiatorRequiredMsg, validate: value => validateWhiteSpaces(value) ? whiteSpaceError : null };
    const destinationValidationObj = { required: destinationRequiredMsg, validate: value => validateWhiteSpaces(value) ? whiteSpaceError : null };
    const deliveryPlaceValidationObj = { required: deliveryPlaceRequiredMsg, validate: value => validateWhiteSpaces(value) ? whiteSpaceError : null };
    const acquisitionTypeObj = { required: acquisitionTypeMsg }
    const engagementValidationObj = { required: false };
    const amountValidationObj = { required: formData?.deadlineTimeType ? amountRequiredMsg : false };
    const deadlineTimeTypeObj = { required: formData?.deadlineAmount ? deadlineTimeTypeMsg : false };
    const observationsValidationObj = { required: false }

    // Validations
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const validateWhiteSpaces = (value) => {
        if (value?.trim() == '') {
            return true;
        } else {
            return false;
        };
    };

    const withValueLimit = (inputObj) => {
        const { value } = inputObj;
        const maxValue = 9999;
        if (value <= maxValue)
            return inputObj;
    };

    // User data
    const listUserData = useSelector(state => getUserListData(state));
    const email = useSelector(state => getEmail(state));
    const userFound = listUserData?.records?.find(item => item?.email == email);
    const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const handleChange = (field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    };
    const handleAdministrativeService = value => { delete errors?.administrativeServiceId; handleChange('administrativeServiceId', value) }
    const handleOrderNumber = value => handleChange('orderNumber', value)
    const handleAdquisitionDate = value => handleChange('adquisitionDate', value)
    const handleAdquisitionType = value => handleChange('acquisitionType', value)
    const handleAdquisitionNumber = value => handleChange('acquisitionNumber', value)
    const handleAdquisitionYear = value => handleChange('acquisitionYear', value)
    const handleOrganismCode = value => handleChange('organismCode', value)
    const handleOrganismNumber = value => handleChange('organismNumber', value)
    const handleOrganismYear = value => handleChange('organismYear', value)
    const handleIniciatorText = value => handleChange('initiatorText', value)
    const handleDestinationText = value => handleChange('destinationText', value)
    const handleDeliveryPlaceText = value => handleChange('deliveryPlaceText', value)
    const handleLegalInstrumentType = value => handleChange('legalInstrumentType', value)
    const handleLegalInstrumentNumber = value => handleChange('legalInstrumentNumber', value)
    const handleLegalInstrumentYear = value => handleChange('legalInstrumentYear', value)
    const handleLegalInstrumentDate = value => handleChange('legalInstrumentDate', value)
    const handleDeadlineAmount = value => handleChange('deadlineAmount', value)
    const handleDeadlineTimeType = value => handleChange('deadlineTimeType', value)
    const handleObservations = value => handleChange('observations', value)
    const handleYear = value => handleChange('fiscalYearId', value)

    useEffect(() => {
        dispatch(tryGetListUserProfile());
        dispatch(tryListServicesByUserAndExercise(userFound?.id, globalSelectedPeriod?.id));
        setCurrentYear(globalSelectedPeriod);
    }, []);

    return <>
        <InputServiceForm formData={formData} handleAdministrativeService={handleAdministrativeService} />
        <Form.Group as={Row}>
            <Form.Label htmlFor='number' className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                {number}:
            </Form.Label>
            <Col sm='4'>
                <div className="input-group">
                    <NumericFormat
                        name='orderNumber'
                        value={formData?.orderNumber}
                        decimalScale={0}
                        inputMode='numeric'
                        className="form-control mr-2"
                        placeholder={numberAbbreviation}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        isAllowed={(inputObj) => withValueLimit(inputObj)}
                        getInputRef={register(numberValidationObj)}
                        onChange={e => handleOrderNumber(e?.target?.value)}
                    />
                    <Form.Control
                        as='select'
                        className='form-control'
                        name='fiscalYearId'
                        id='globalSelectedPeriod'
                        value={formData?.fiscalYearId}
                        onChange={(e) => handleYear(e.target.value)}
                        ref={register(fiscalYearIdValidationObj)}
                    >
                        <option value={''}>Seleccionar</option>
                        <option value={currentYear?.year}>{currentYear?.year}</option>
                        <option value={currentYear?.year + 1}>{currentYear?.year + 1}</option>
                    </Form.Control>
                </div>
                <FormFieldError errors={errors?.orderNumber} />
                <FormFieldError errors={errors?.fiscalYearId} />
            </Col>
        </Form.Group>
        <Form.Group as={Row}>
            <Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                Fecha de orden de provisión:
            </Form.Label>
            <Col sm='4'>
                <Form.Control
                    type='date'
                    name='orderDate'
                    ref={register(dateValidationObj)}
                    max={todayDate}
                    defaultValue={provisionOrder?.provisionOrderDate}
                    onChange={e => handleAdquisitionDate(e?.target?.value)}
                />
                <FormFieldError errors={errors?.orderDate} />
            </Col>
        </Form.Group>
        <InputOrganismForm formData={formData} handleOrganismCode={handleOrganismCode} handleOrganismNumber={handleOrganismNumber} handleOrganismYear={handleOrganismYear} />
        <Form.Group as={Row}>
            <Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                Iniciador:
            </Form.Label>
            <Col sm='4'>
                <Form.Control
                    type='text'
                    name='initiator'
                    value={formData?.initiatorText}
                    placeholder='Ingresar Iniciador'
                    onChange={e => handleIniciatorText(e?.target?.value)}
                    ref={register(initiatorValidationObj)}
                    maxLength="255"
                />
                <FormFieldError errors={errors?.initiator} />
            </Col>
        </Form.Group>
        <Form.Group as={Row}>
            <Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                Destino:
            </Form.Label>
            <Col sm='4'>
                <Form.Control
                    type='text'
                    name='destination'
                    value={formData?.destinationText}
                    placeholder='Ingresar Destino'
                    onChange={e => handleDestinationText(e?.target?.value)}
                    ref={register(destinationValidationObj)}
                    maxLength="255"
                />
                <FormFieldError errors={errors?.destination} />
            </Col>
        </Form.Group>
        <Form.Group as={Row}>
            <Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                Lugar de entrega:
            </Form.Label>
            <Col sm='4'>
                <Form.Control
                    type='text'
                    name='deliveryPlace'
                    value={formData?.deliveryPlaceText}
                    placeholder='Ingresar Lugar de Entrega'
                    onChange={e => handleDeliveryPlaceText(e?.target?.value)}
                    ref={register(deliveryPlaceValidationObj)}
                    maxLength="255"
                />
                <FormFieldError errors={errors?.deliveryPlace} />
            </Col>
        </Form.Group>
        <Form.Group as={Row}>
            <Form.Label className='text-right d-flex mandatory-label' column sm='4'>
                Plazo de Entrega:
            </Form.Label>
            <Col sm='4'>
                <div className="input-group" >
                    <Form.Control
                        type='text'
                        name='deadlineAmount'
                        value={formData?.deadlineAmount}
                        className='mr-2'
                        placeholder='Cantidad'
                        maxLength="10"
                        onChange={e => handleDeadlineAmount(e.target.value)}
                        ref={register(amountValidationObj)}
                    />
                    <Form.Control
                        name='deadlineTimeType'
                        value={formData?.deadlineTimeType}
                        type='text'
                        className="form-control"
                        placeholder={'Dias, Semanas, Mes'}
                        onChange={e => handleDeadlineTimeType(e.target.value)}
                        ref={register(deadlineTimeTypeObj)}
                    />
                </div>
                {((formData?.deadlineAmount && !formData?.deadlineTimeType) || (!formData?.deadlineAmount && formData?.deadlineTimeType))
                    && (
                        <Alert variant='warning' className='mt-1'>
                            <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                            Por favor completa ambos campos si completas uno.
                        </Alert>
                    )}
            </Col>
        </Form.Group>
        <InputContratacionForm
            adquisition={formData}
            handleAdquisitionType={handleAdquisitionType}
            handleAdquisitionNumber={handleAdquisitionNumber}
            handleAdquisitionYear={handleAdquisitionYear}
        />
        <InputCuitForm inputName={'Proveedor: '} provider={provisionOrder?.beneficiary} />
        <InputLegalInstrumentForm
            legalInstrument={formData}
            handleLegalInstrumentType={handleLegalInstrumentType}
            handleLegalInstrumentNumber={handleLegalInstrumentNumber}
            handleLegalInstrumentYear={handleLegalInstrumentYear}
        />
        <Form.Group as={Row}>
            <Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                Fecha del instrumento legal:
            </Form.Label>
            <Col sm='4'>
                <Form.Control
                    type='date'
                    name='legalInstrumentDate'
                    ref={register(dateValidationObj)}
                    max={todayDate}
                    defaultValue={provisionOrder?.legalInstrument?.date}
                    onChange={e => handleLegalInstrumentDate(e.target.value)}
                />
                <FormFieldError errors={errors?.legalInstrumentDate} />
            </Col>
        </Form.Group>

        <Form.Group as={Row}>
            <Form.Label className='text-right d-flex mandatory-label' column sm={4}>
                Observación:
            </Form.Label>
            <Col sm={4}>
                <Form.Control
                    ref={register(observationsValidationObj)}
                    onChange={e => handleObservations(e?.target?.value)}
                    as='textarea'
                    placeholder={'Observación'}
                    name='observations'
                    disabled={false}
                    maxLength={10000}
                    rows={2}
                    value={formData?.observations}
                />
            </Col>
        </Form.Group>
    </>
};

export default ProvisionOrderForm;